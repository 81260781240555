.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.container-wrapper {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.menu-item {
  padding: 10px 15px 10px 15px;
  background-color: #fff;
  margin-bottom: 2px;
  border-radius: 8;

  .price-container {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .name {
    font-family: "PlusJakartaSans_600SemiBold";
    font-size: 16px;
    line-height: 20px;
    color: #000;
  }

  .price {
    font-family: "PlusJakartaSans_600SemiBold";
    font-size: 16px;
    line-height: 20px;
    color: "#000";
  }

  .description {
    font-family: "PlusJakartaSans_400Regular";
    font-size: 14px;
    color: "#535353";
    padding-bottom: 11px;
    padding-top: 11px;
  }

  .image {
    aspect-ratio: 1;
    width: 100px;
    border-radius: 8px;
  }
}

.section-heading {
  width: 100%;
  height: 20px;
  text-align: center;
  margin: 30px 0 40px;
  /* color */
  color: #514bf0;
  border-bottom: 2px #514bf0 solid;
}

.section-title {
  background: #f1f1f1;
  font-size: 23px;
  padding: 10px;
  background-color: #fff;
}

.title {
  font-size: 25px;
  font-family: "PlusJakartaSans_600SemiBold";
  color: "#000";
  margin-top: 20px;
  margin-bottom: 20px;
  color: #514bf0;
}
